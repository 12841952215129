import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

const NewsletterSignup = () => {
  const SUBSCRIBE_SERVER_URL =
    'https://l3hb79v1h4.execute-api.us-west-2.amazonaws.com/subscription'
  const DripNewsletterSignupTags = ['newsletter']
  const FormState = Object.freeze({
    NEW: 0,
    SUCCESS: 1,
    ERROR: 2,
  })

  const [signupFormState, setSignupFormState] = useState(FormState.NEW)

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm()
  const onSubmit = (data, event) => {
    event.preventDefault()
    data.tags = DripNewsletterSignupTags

    fetch(SUBSCRIBE_SERVER_URL, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result)
        reset()
        setSignupFormState(FormState.SUCCESS)
      })
      .catch((error) => {
        console.error('Error:', error)
        setSignupFormState(FormState.ERROR)
      })
  }

  if (signupFormState === FormState.SUCCESS) {
    return (
      <div className="signup-form-wrapper">
        <h2>Thanks for signing up!</h2>
      </div>
    )
  }

  return (
    <div className="signup-form-wrapper">
      <h2>Sign Up For Newsletter Updates!</h2>
      <form className="signup-form" onSubmit={handleSubmit(onSubmit)}>
        <input
          id="first_name"
          placeholder="First Name"
          type="text"
          className={errors.first_name ? 'input-error' : ''}
          {...register('first_name', { required: 'First name required' })}
          aria-invalid={errors.first_name ? 'true' : 'false'}
        />
        {errors.first_name && (
          <p className="alert">{errors.first_name?.message}</p>
        )}
        <input
          id="last_name"
          placeholder="Last Name"
          type="text"
          className={errors.last_name ? 'input-error' : ''}
          {...register('last_name', { required: 'Last name required' })}
          aria-invalid={errors.last_name ? 'true' : 'false'}
        />
        {errors.last_name && (
          <p className="alert">{errors.last_name?.message}</p>
        )}
        <input
          id="email"
          placeholder="email@example.com"
          type="email"
          className={errors.email ? 'input-error' : ''}
          {...register('email', {
            required: 'Email Address is required',
            pattern: {
              value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
              message: 'Invalid email format',
            },
          })}
        />
        {errors.email && <p className="alert">{errors.email?.message}</p>}
        <input
          className={
            signupFormState != FormState.NEW
              ? 'submit special disabled'
              : 'submit special'
          }
          type="submit"
          value="Subscribe"
        />
        {signupFormState === FormState.ERROR && (
          <p className="alert">Oops! Something went wrong!</p>
        )}
      </form>
    </div>
  )
}

export default NewsletterSignup

// fetch(SUBSCRIBE_SERVER_URL, {
//   method: 'POST',
//   mode: 'no-cors',
//   cache: 'no-cache',
//   credentials: 'same-origin',
//   headers: {
//     'Content-Type': 'form-data',
//   },
//   body: formData,
// })
